import React from "react";
// Classes and scripts
import "uikit/dist/css/uikit.min.css"
import "uikit/dist/js/uikit.min"
import "@fancyapps/ui/dist/fancybox.css";
import { Fancybox } from "@fancyapps/ui";
import "./src/assets/css/normalize.min.css"
import "./src/assets/icons/flaticon.css"
import "./src/assets/css/fonts.css"
import "./src/assets/css/pixeicons.css"
import "./src/assets/css/style.css"

export const onClientEntry = () => {
  if (document.getElementById("page-preloader")) {
    document.getElementById("page-preloader").style.display = "flex";
  }
};

export const onInitialClientRender = () => {
  if (document.getElementById("page-preloader")) {
    document.getElementById("page-preloader").style.display = "flex";
  }
};

export const onRouteUpdate = () => {
  setTimeout(() => {
    if (document.getElementById("page-preloader")) {
      document.getElementById("page-preloader").style.display = "none";
    }
  }, 500);
};
